import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import { soinsTypes } from '@/views/Administration/Constants'
import Commons from '../../Helpers/commons'
import { AppUser } from '@/models/app-user-dto'
import { RoleGroups } from '@/shared/constants/Constants'

@Component({
  components: {
    Alert,
    ValidationObserver,
    ValidationProvider
  }
})
export default class UserDialog extends Vue {
  @Prop()
  public visible!: boolean

  @Prop()
  public showPasswordView!: boolean

  @Prop()
  public isLoading!: boolean

  @Prop({ default: {} })
  public editedItem!: AppUser

  @Prop({ default: [] })
  public errorMessages!: any[]

  public roleGroups = RoleGroups

  public showEye = false
  public editPassword = false
  public passConfirm = ''
  public soinTypesItems = soinsTypes

  get show () {
    return this.visible
  }

  public emitTogglePasswordView () {
    this.editPassword = !this.editPassword
    this.$emit('togglePasswordViewClick')
  }

  public async emitSaveClick () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.$emit('saveClick')
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public emitClose () {
    this.passConfirm = ''
    this.showEye = false
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()
    this.$emit('close')
  }

  public get isEditMode () {
    return !!this.editedItem.id
  }
}
