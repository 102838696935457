var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasUserManagementAccess)?_c('v-container',{staticClass:"main-container",attrs:{"fluid":""}},[_c('div',{staticClass:"page-title"},[_c('h2',[_vm._v("Gestion des utilisateurs")])]),_c('alert',{attrs:{"value":_vm.showAlert,"type":_vm.alertType,"canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.alertMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]),_c('c-btn',{staticClass:"btn-red",on:{"click":function($event){$event.stopPropagation();return _vm.openUserDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-plus ")]),_c('span',[_vm._v("Ajouter un utilisateur")])],1),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.isLoading,"sort-by":['fullName'],"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isLoading }},scopedSlots:_vm._u([{key:"item.groupIdList",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"chips-group"},_vm._l((item.groupIdList),function(grpId,i){return _c('c-chip',{key:("grp-" + i),attrs:{"color":_vm.getColor(grpId)}},[_vm._v(" "+_vm._s(_vm.getGroupName(grpId))+" ")])}),1)]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [(item.isEnabled)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-minus-circle-outline ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){$event.stopPropagation();return _vm.displayConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}},{key:"no-data",fn:function(){return [(!_vm.isLoading && !_vm.users.length)?_c('p',{staticClass:"leader"},[_vm._v("Aucun utilisateur trouvé")]):_vm._e(),_c('c-btn',{attrs:{"loading":_vm.isLoading},on:{"click":function($event){return _vm.getAllUsers()}}},[_vm._v(" Actualiser ")])]},proxy:true}],null,true)}),_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":("Supprimer " + (_vm.editedItem.email)),"message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer l'utilisateur ?"},on:{"close":function($event){return _vm.confirmCallback($event)}}}),_c('user-dialog',{attrs:{"visible":_vm.dialog,"editedItem":_vm.editedItem,"showPasswordView":_vm.showPasswordView,"errorMessages":_vm.errorMessages,"isLoading":_vm.isLoading},on:{"close":function($event){return _vm.closeUserDialog()},"togglePasswordViewClick":_vm.togglePasswordView,"saveClick":function($event){return _vm.save()}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }