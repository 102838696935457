import { RoleGroup, UserRoles } from '@/shared/constants/role-enums'

export class UserManagerHelper {
  private UserRolesRepo: { [id: string]: UserRoles[] } = {}
  public roles: string[] = []
  constructor () {
    this.populateRolesInCategory()
    this.getGroupEnumNames()
  }

  public convertGroupNamesToNumber (
    groupNames: string[],
    increment = false
  ) {
    const groupEnumIds: number[] = []
    groupNames.forEach((grpname) => {
      const idx = increment
        ? this.roles.indexOf(grpname)
        : this.roles.indexOf(grpname) + 1
      groupEnumIds.push(idx)
    })
    return groupEnumIds
  }

  private getGroupEnumNames () {
    for (const enumValue in RoleGroup) {
      if (typeof RoleGroup[enumValue] === 'number') {
        this.roles.push(enumValue)
      }
    }
  }

  public getRoleDetails (groupIdx: number[]) {
    let res: UserRoles[] = []
    groupIdx.forEach((idx) => {
      res = res.concat(this.UserRolesRepo[`${idx + 1}`])
    })
    return [...new Set(res)]
  }

  populateRolesInCategory () {
    this.UserRolesRepo[RoleGroup.Administration.toString()] = this.getAdministrationRoles()
    this.UserRolesRepo[RoleGroup.Gestion.toString()] = this.getAllRolesForCategory()
    this.UserRolesRepo[RoleGroup.Diététitiennes.toString()] =
    this.UserRolesRepo[RoleGroup.Infirmières.toString()] =
    this.UserRolesRepo[RoleGroup.Cliniciennes.toString()] = this.getAllRolesForCategory()
    this.UserRolesRepo[RoleGroup.DirectionLDS.toString()] = this.getDirectionRoles()
    this.UserRolesRepo[RoleGroup.ComptaLDS.toString()] = this.getComptaRoles()
  }

  private getAllRolesForCategory () {
    return [
      UserRoles.Creators,
      UserRoles.ViewJourPatients,
      UserRoles.Editpatients,
      UserRoles.SearchPatients,
      UserRoles.ViewAdminpage,
      UserRoles.EditAdminPage,
      UserRoles.EditNurseData,
      UserRoles.ViewNurseData,
      UserRoles.ViewAgenda,
      UserRoles.EditAgenda,
      UserRoles.ViewPrescriptions,
      UserRoles.EditPrescriptions
    ]
  }

  private getDirectionRoles () {
    return [
      UserRoles.ViewJourPatients,
      UserRoles.SearchPatients,
      UserRoles.ViewAdminpage,
      UserRoles.ViewNurseData,
      UserRoles.ViewAgenda,
      UserRoles.ViewPrescriptions
    ]
  }

  private getComptaRoles () {
    return [
      UserRoles.ViewJourPatients,
      UserRoles.SearchPatients,
      UserRoles.ViewAdminpage,
      UserRoles.ViewAgenda,
      UserRoles.ViewPrescriptions
    ]
  }

  private getAdministrationRoles () {
    return [
      UserRoles.Creators,
      UserRoles.ViewJourPatients,
      UserRoles.Editpatients,
      UserRoles.SearchPatients,
      UserRoles.ViewAdminpage,
      UserRoles.EditAdminPage,
      UserRoles.ViewNurseData,
      UserRoles.ViewAgenda,
      UserRoles.EditAgenda,
      UserRoles.ViewPrescriptions,
      UserRoles.EditPrescriptions
    ]
  }

  public getColor (groupId: string) {
    if (+groupId === 1) {
      return 'primary'
    } else if (+groupId === 2) {
      return 'deep-purple accent-4'
    } else if (+groupId < 6) {
      return 'success'
    } else if (+groupId < 8) {
      return 'blue'
    } else if (+groupId === 9) {
      return 'indigo darken-3'
    } else {
      return 'gray'
    }
  }
}
